
const attributeExceptions = [
  'for',
  'role',
  'data-title',
  'data-direction'
]

const appendText = (el, text) => {
  const textNode = document.createTextNode(text)
  el.appendChild(textNode)
}

const appendArray = (el, children) => {
  children.forEach((child) => {
    if (Array.isArray(child)) {
      appendArray(el, child)
    } else if (child instanceof window.Element) {
      el.appendChild(child)
    } else if (typeof child === 'string') {
      appendText(el, child)
    }
  })
}

const setStyles = (el, styles) => {
  if (!styles) {
    el.removeAttribute('styles')
    return
  }

  Object.keys(styles).forEach((styleName) => {
    if (styleName in el.style) {
      el.style[styleName] = styles[styleName] // eslint-disable-line no-param-reassign
    } else {
      console.log(`${styleName} is not a valid style for a <${el.tagName.toLowerCase()}>`) // eslint-disable-line no-console
    }
  })
}

const makeElement = (type, textOrPropsOrChild, ...otherChildren) => {
  const el = document.createElement(type)

  if (Array.isArray(textOrPropsOrChild)) {
    appendArray(el, textOrPropsOrChild)
  } else if (textOrPropsOrChild instanceof window.Element) {
    el.appendChild(textOrPropsOrChild)
  } else if (typeof textOrPropsOrChild === 'string') {
    appendText(el, textOrPropsOrChild)
  } else if (typeof textOrPropsOrChild === 'object') {
    Object.keys(textOrPropsOrChild).forEach((propName) => {
      if (propName in el || attributeExceptions.includes(propName)) {
        const value = textOrPropsOrChild[propName]

        if (propName === 'style') {
          setStyles(el, value)
        } else if (/\b(data-)\w+\b/.test(propName)) {
          el.setAttribute(propName, value)
        } else if (value) {
          el[propName] = value
        }
      } else {
        console.log(`${propName} is not a valid property of a <${type}>`) // eslint-disable-line no-console
      }
    })
  }

  if (otherChildren) appendArray(el, otherChildren)

  return el
}

/* eslint-disable no-unused-vars */

const a        = (...args) => makeElement('a', ...args)
const i        = (...args) => makeElement('i', ...args)
const label    = (...args) => makeElement('label', ...args)
const input    = (...args) => makeElement('input', ...args)
const button   = (...args) => makeElement('button', ...args)
const div      = (...args) => makeElement('div', ...args)
const h1       = (...args) => makeElement('h1', ...args)
const h2       = (...args) => makeElement('h2', ...args)
const h3       = (...args) => makeElement('h3', ...args)
const header   = (...args) => makeElement('header', ...args)
const p        = (...args) => makeElement('p', ...args)
const b        = (...args) => makeElement('b', ...args)
const span     = (...args) => makeElement('span', ...args)
const ul       = (...args) => makeElement('ul', ...args)
const li       = (...args) => makeElement('li', ...args)
const datalist = (...args) => makeElement('datalist', ...args)
const select   = (...args) => makeElement('select', ...args)
const option   = (...args) => makeElement('option', ...args)

const article   = (...args) => makeElement('article', ...args)
const aside     = (...args) => makeElement('aside', ...args)
const small     = (...args) => makeElement('small', ...args)
const img       = (...args) => makeElement('img', ...args)

/* eslint-enable no-unused-vars */


const mount = document.getElementById('news__mount')

let featured  = div({ className: 'news__featured--container' })
let secondary = div({ className: 'news__secondary--container'})
let all_news  = a({
  title: 'Alla nyheter',
  href:  '/nyheter',
  className: 'news__all'
})

mount.appendChild(featured)
mount.appendChild(secondary)
mount.parentNode.appendChild(all_news)

$.get('News/GetNews', function (data) {
  mount.className += 'spinner'
}).success( data => {

  let feat = data.splice(0, 1)

  featured.appendChild(NewsCard(feat[0], true))

// for now, get only four of the newest news...
  data.map((item) => secondary.appendChild(NewsCard(item)))

// secondary.innerHTML = rest.join('')

// let featured_headline = document.querySelectorAll('.news__card--featured .news__card--headline')[0]
// let featured_image    = document.querySelectorAll('.news__card--featured .news__card--image')[0]
// let featured_image_bg = document.querySelectorAll('.news__card--featured .news__card--image__bg')[0]

// setTimeout(() => {
//   featured_image_bg.style.height = featured_headline.offsetHeight + 'px'
//   featured_headline.style.top = (featured_image.offsetHeight - featured_headline.offsetHeight) + 'px'
// }, 250)

}).done( data => {
  mount.className = ''
}).fail( error => {
  mount.className = ''
})


const NewsCard = (props, featured) => {

  let link = {
    url  : '/nyheter/' + props.url,
    text : 'Läs mer'
  }

  // let image   = NewsCardImage(props.image, link)
  // let title   = NewsCardTitle(props.headline, props.date_created, props.lead)
  // let cls     = featured ? 'news__card,news__card--featured' : 'news__card'
  // let content = featured ? NewsCardContent(props.content, link) : NewsCardLink(link)
  // let tags    = NewsCardTags(props.tags)

  return article({
    className: featured ? 'news__card news__card--featured' : 'news__card',
    onclick: () => window.location.href = link.url
  }, [
    NewsCardImage(props.image),
    NewsCardTitle(props.headline, props.date_created, props.lead),
    featured ? NewsCardContent(props.content, link) : ''
  ])
}

const NewsCardImage = (image) => {

  let imgc = image !== null ? img({
    src: `data:image/jpeg;base64,${image.data}`
  }) : i({ className: 'material-icons'}, 'message')

  return aside({
    className: 'news__card--image'
  }, [imgc]
  )

}

const NewsCardTitle = (headline, date, lead) => {

  return div([
    h2({
      className: 'news__card--headline'
    }, headline),
    small(date),
    p({
      className: 'news__card--lead'
    }, lead)
  ])
}

const NewsCardContent = (content, link) => {
  let c = document.createElement('div')
  c.innerHTML = content
  return div({
    className: 'news__card--content'
  }, [
    c,
    a({
      className: 'news__card--more',
      href: link.url
    }, link.text)
  ])
}

// const NewsCardTags = tags => {
//   let t = tags.splice(0, 2).map(tag => {
//     return '<a class="news__card--tag" src="asd">' + tag.Text + '</a>'
//   })
//   return '<div class="news__card--tags">' +
// '<i class="material-icons">label</i>' +
// t.join('') +
// '</div>'
// }

